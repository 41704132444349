
import { defineComponent, provide, watch } from 'vue'
import DeviationCrewTable from '@/components/deviation/deviation-crew/DeviationCrewTable.vue'
import { useDeviationCrew } from '@/composable/useDeviationCrew'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationCrewFormModal from '@/components/deviation/deviation-crew/DeviationCrewFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'CrewDeviations',

  components: {
    DeviationCrewTable,
    DeviationDeleteModal,
    DeviationCrewFormModal,
    DeviationFilterModal,
  },

  setup() {
    const { can } = useProfile()
    const { data, loading, fetchAll, period } = useDeviationCrew()
    const deviationCrewFormModal = useModal()
    const deviationCrewDeleteModal = useModal()
    provide('deviation-crew-form-modal', deviationCrewFormModal)
    provide('deviation-delete-modal', deviationCrewDeleteModal)

    watch(period, fetchAll, { immediate: true })

    return {
      addColumns,
      data,
      loading,
      deviationCrewFormModal,
      deviationCrewDeleteModal,
      can,
    }
  },
})
