<template>
  <q-page>
    <q-dialog v-model="deviationCrewFormModal.state.show" :persistent="false">
      <DeviationCrewFormModal
        show-event-input
        v-if="deviationCrewFormModal.state.show"
      />
    </q-dialog>
    <q-dialog v-model="deviationCrewDeleteModal.state.show" :persistent="false">
      <DeviationDeleteModal v-if="deviationCrewDeleteModal.state.show" />
    </q-dialog>
    <DeviationCrewTable
      :addColumns="addColumns"
      :loading="loading.getAll"
      height="calc(100vh - 50px - 68px)"
      :deviation-crews="data"
    >
      <template v-slot:top-left-buttons>
        <h6 class="q-ma-none">Bemanning</h6>
        <q-btn
          v-if="can('deviationManagement.deviationCrew.create')"
          icon-right="mdi-plus-circle-outline"
          class="q-ml-md"
          label="Lägg till"
          color="primary"
          @click="() => deviationCrewFormModal.openModal({ mode: 'create' })"
        />
      </template>

      <template v-slot:top-right-buttons>
        <DeviationFilterModal deviation-type="deviationCrew" />
      </template>
    </DeviationCrewTable>
  </q-page>
</template>

<script lang="ts">
import { defineComponent, provide, watch } from 'vue'
import DeviationCrewTable from '@/components/deviation/deviation-crew/DeviationCrewTable.vue'
import { useDeviationCrew } from '@/composable/useDeviationCrew'
import { useModal } from '@/composable/useModal'
import DeviationDeleteModal from '@/components/deviation/DeviationDeleteModal.vue'
import DeviationCrewFormModal from '@/components/deviation/deviation-crew/DeviationCrewFormModal.vue'
import DeviationFilterModal from '@/components/deviation/DeviationFilterModal.vue'
import { useProfile } from '@/composable/useProfile'
import { addColumns } from '@/common/deviationAddColumns'

export default defineComponent({
  name: 'CrewDeviations',

  components: {
    DeviationCrewTable,
    DeviationDeleteModal,
    DeviationCrewFormModal,
    DeviationFilterModal,
  },

  setup() {
    const { can } = useProfile()
    const { data, loading, fetchAll, period } = useDeviationCrew()
    const deviationCrewFormModal = useModal()
    const deviationCrewDeleteModal = useModal()
    provide('deviation-crew-form-modal', deviationCrewFormModal)
    provide('deviation-delete-modal', deviationCrewDeleteModal)

    watch(period, fetchAll, { immediate: true })

    return {
      addColumns,
      data,
      loading,
      deviationCrewFormModal,
      deviationCrewDeleteModal,
      can,
    }
  },
})
</script>
